<template>
  <v-container>
    <v-row>
    	<v-col cols="12">
	      <div class="text-h6 mt-4"><b>¿Quiénes SOMOS?</b></div>

	      <br/>
				<div class="text-h5 mt-4">Equipos, MATERIAL Y MANTENIMIENTOS</div>

				<br/>

				Innovalab es una empresa mexicana con más de 14 años de experiencia dedicada a la comercialización de instrumentos, equipos y mobiliario para laboratorio, llevando así nuevas tecnologías en las áreas de investigación y producción, ambas áreas potenciales para el crecimiento del país. 

				<br/>
				<br/>

				Al comprender y conocer las necesidades de las personas podremos ofrecer verdaderas soluciones para el desarrollo de su empresa. Saber que los motiva, con que dilemas se enfrentan y cuál es la mejor manera de ayudarlos nos permite superar sus expectativas e impulsar el éxito de nuestros clientes y su empresa.

				<br/>
				<br/>
				Contamos con un centro de servicio técnico calificado, destacamos por ser una de las pocas empresas en la República Mexicana que brindan servicio técnico preventivo y correctivo a equipos de diversas compañías del área farmacéutica, alimentos, investigación, sector educativo, de la salud, entre otras.

				<br/>
				<br/>
				<br/>
		    
		    Productos especializados en:

				<v-list nav dense class="transparent">
		      <v-list-item-group color="primary">
		        <v-list-item>
		          <v-list-item-content>
		            <v-list-item-title><ul><li>Farmacia e Industria</li></ul></v-list-item-title>
		          </v-list-item-content>
		        </v-list-item>

		        <v-list-item>
		          <v-list-item-content>
		            <v-list-item-title><ul><li>Control de calidad</li></ul></v-list-item-title>
		          </v-list-item-content>
		        </v-list-item>

		        <v-list-item>
		          <v-list-item-content>
		            <v-list-item-title><ul><li>Control físico químico</li></ul></v-list-item-title>
		          </v-list-item-content>
		        </v-list-item>

		        <v-list-item>
		          <v-list-item-content>
		            <v-list-item-title><ul><li>Investigación y desarrollo</li></ul></v-list-item-title>
		          </v-list-item-content>
		        </v-list-item>

		        <v-list-item>
		          <v-list-item-content>
		            <v-list-item-title><ul><li>Cosméticos y fragancias</li></ul></v-list-item-title>
		          </v-list-item-content>
		        </v-list-item>

		        <v-list-item>
		          <v-list-item-content>
		            <v-list-item-title><ul><li>Polímeros y aceites</li></ul></v-list-item-title>
		          </v-list-item-content>
		        </v-list-item>

		        <v-list-item>
		          <v-list-item-content>
		            <v-list-item-title><ul><li>Alimentos y bebidas</li></ul></v-list-item-title>
		          </v-list-item-content>
		        </v-list-item>

		      </v-list-item-group>
		    </v-list>

		    <br/>
		    <br/>

	    	<v-row justify="space-between">

	    		<v-col cols="12" md="6" lg="4">
	    			<v-card class="shadowCard white--text" color="#1999BC">
	    				<v-card-title primary-title>
	    				  <v-spacer></v-spacer>
	    				  MISIÓN
	    				  <v-spacer></v-spacer>
	    				</v-card-title>
	    				<v-card-text class="text-center white--text">
	    				  CUMPLIR CON LAS EXIGENCIAS DE NUESTROS CLIENTES A TRAVES DE UN ANALISIS PRECISO Y CONFIABLE PARA SU DESARROLLO.
	    				</v-card-text>
	    			</v-card>
	    		</v-col>

	    		<v-col cols="12" md="6" lg="4">
	    			<v-card class="shadowCard white--text" color="#1999BC">
	    				<v-card-title primary-title>
	    				  <v-spacer></v-spacer>
	    				  VISIÓN
	    				  <v-spacer></v-spacer>
	    				</v-card-title>
	    				<v-card-text class="text-center white--text">
	    				  PARA EL 2024 LLEGAR A LA ZONA CENTRO Y SUR DEL PAIS, PARA BRINDAR LA MEJOR CALIDAD Y SERVICIO.
	    				</v-card-text>
	    			</v-card>
	    		</v-col>
	    	</v-row>

	    	<v-row>
	    		<v-col cols="12">
	    			<v-card class="elevation-0 transparent">
	    			  <v-card-title primary-title class="text-h4">
	    			    <v-spacer></v-spacer>
	    			    Valores
	    			    <v-spacer></v-spacer>
	    			  </v-card-title>
	    			</v-card>
	    		</v-col>
	    	</v-row>

	    	<v-row>
	    		<v-col cols="12" md="4" lg="2">
	    			<v-card class="shadowCard white--text" color="orange">
	    				<v-card-title primary-title class="text-subtitle-1 white--text font-weight-semibold">
	    				  <v-spacer></v-spacer>
	    				  TRANSPARENCIA
	    				  <v-spacer></v-spacer>
	    				</v-card-title>
	    			</v-card>
	    		</v-col>

	    		<v-col cols="12" md="4" lg="2">
	    			<v-card class="shadowCard white--text" color="orange">
	    				<v-card-title primary-title class="text-subtitle-1 white--text font-weight-semibold">
	    				  <v-spacer></v-spacer>
	    				  PERSISTENCIA
	    				  <v-spacer></v-spacer>
	    				</v-card-title>
	    			</v-card>
	    		</v-col>

	    		<v-col cols="12" md="4" lg="2">
	    			<v-card class="shadowCard white--text" color="orange">
	    				<v-card-title primary-title class="text-subtitle-1 white--text font-weight-semibold">
	    				  <v-spacer></v-spacer>
	    				  SEGURIDAD
	    				  <v-spacer></v-spacer>
	    				</v-card-title>
	    			</v-card>
	    		</v-col>

	    		<v-col cols="12" md="4" lg="2">
	    			<v-card class="shadowCard white--text" color="orange">
	    				<v-card-title primary-title class="text-subtitle-1 white--text font-weight-semibold">
	    				  <v-spacer></v-spacer>
	    				  EMPATIA
	    				  <v-spacer></v-spacer>
	    				</v-card-title>
	    			</v-card>
	    		</v-col>

	    		<v-col cols="12" md="4" lg="2">
	    			<v-card class="shadowCard white--text" color="orange">
	    				<v-card-title primary-title class="text-subtitle-1 white--text font-weight-semibold">
	    				  <v-spacer></v-spacer>
	    				  AMABILIDAD
	    				  <v-spacer></v-spacer>
	    				</v-card-title>
	    			</v-card>
	    		</v-col>

	    		<v-col cols="12" md="4" lg="2">
	    			<v-card class="shadowCard white--text" color="orange">
	    				<v-card-title primary-title class="text-subtitle-1 white--text font-weight-semibold">
	    				  <v-spacer></v-spacer>
	    				  COHERENCIA
	    				  <v-spacer></v-spacer>
	    				</v-card-title>
	    			</v-card>
	    		</v-col>

	    		<v-col cols="12" md="4" lg="2">
	    			<v-card class="shadowCard white--text" color="orange">
	    				<v-card-title primary-title class="text-subtitle-1 white--text font-weight-semibold">
	    				  <v-spacer></v-spacer>
	    				  INNOVACION
	    				  <v-spacer></v-spacer>
	    				</v-card-title>
	    			</v-card>
	    		</v-col>
	    	</v-row>
    	</v-col>

    </v-row>
  </v-container>
</template>

<script>
  import $ from 'jquery'
  import {mapActions , mapGetters} from 'vuex'
	import metodos from '@/mixins/metodos.js';
  import Vue from 'vue'
	var accounting = require("accounting");
  Vue.filter('currency', (val, dec) => {
    return accounting.formatMoney(val, '$', dec)
  })

  import card_articulo from '@/components/card_articulo.vue'

  export default {
		mixins:[metodos],

    components: {
      card_articulo,
    },

    data (){
      return {
        marcas:[],
        url   : this.$http.options.root + 'fotos-fetish/' ,
      }
    },

    created(){
      this.init();
      console.log(this.seguimiento)
    },

    computed:{
      ...mapGetters('Listado',['Loading','listado_productos','seguimiento']),
      ...mapGetters('login'  ,['getusuarioFetish']),
    },

    methods:{

      init(){
      	this.marcas = []

				this.$http.get('marcas.list').then( response =>{
					this.marcas = response.body
					console.log( this.marcas)
				}).catch( error =>{
					this.validarError(error )
				})
      },
    }
  }
</script>

